
import { Script } from 'gatsby'

import Layout from './src/layout/Layout'

const GTM_ID = process.env.GTM_ID


export const wrapPageElement = ({ element, props }) => {
	return <Layout	{...props}>

		{ element }

		{ process.env.NODE_ENV === 'production' && <>
			<Script id='gtag-config' strategy='idle'>
				{`
					window.dataLayer = window.dataLayer || [];
					window.gtag = function() { dataLayer.push(arguments); };
					gtag('consent', 'default', {
						ad_storage: 'denied',
						ad_user_data: 'denied',
						ad_personalization: 'denied',
						analytics_storage: 'denied',
						functionality_storage: 'denied',
						personalization_storage: 'denied',
						security_storage: 'granted',
						wait_for_update: 500
					});
					gtag('js', new Date());
					gtag('config', '${GTM_ID}');
				`}
			</Script>
			<Script strategy='idle' src={`https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`} />
			<Script strategy='idle' src='/cookie-consent.js' />
			<Script strategy='idle' src='https://comparamais.leado.pl/js/leado-subscribe.js' />
		</> }

	</Layout>
}


export const onRenderBody = ({ 
	setHtmlAttributes, 
	setHeadComponents,
	setPreBodyComponents,
}) => {

	setHtmlAttributes({ lang: 'pt-PT' })

	if (process.env.NODE_ENV === 'production') {

		const headComponents = [
			// <link key='gtm-dns-prefetch' rel='dns-prefetch' href='https://www.googletagmanager.com' />,
			// <script key='cookie-consent' defer src='/cookie-consent.js' />,
			// <script key='partytown-vanilla-config'>
			// 	{`
			// 		window.partytown = {
			// 			debug: true,
			// 			resolveUrl: function (url, location, type) {
			// 				proxyUrl = new URL(location.origin + \`/__third-party-proxy/\` + url.href);
			// 				return proxyUrl;
			// 			},
			// 		};
			// 	`}
			// </script>,
			<link key='sitemap' rel='sitemap' type='application/xml' href='/sitemap-index.xml' />,
		]
		setHeadComponents(headComponents)

		setPreBodyComponents([
			<noscript key='gtm-noscript'>
				<iframe height='0' width='0' style={{ display: 'none', visibility: 'hidden' }} 
					src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}					
				/>
			</noscript>
		])
	}
}


export const onPreRenderHTML = ({ getHeadComponents, replaceHeadComponents }) => {

	const headComponents = getHeadComponents()

	for (const component of headComponents) {
		if (component.type === 'link' && component.props?.rel === 'preload')
			component.props.crossorigin = ''
	}

	headComponents.sort((a, b) => {
		if (a.props?.rel === 'dns-prefetch') return -1
		if (b.props?.rel === 'dns-prefetch') return 1
		return 0
	})

	replaceHeadComponents(headComponents)
}



import { Link } from 'gatsby'

import spriteSvg from './sprite.svg'


export default function SiteLink() {
	return <Link to='/' className='SiteLink' aria-label="Comparamais.pt">
		<svg
			className='SiteLogo'
			viewBox="0 0 109 24" 
			width="109" 
			height="24"  
			alt="Comparamais.pt"
			xmlns="http://www.w3.org/2000/svg"
		>
			<use href={`${spriteSvg}#comparamais-logo`} />
		</svg>
	</Link>
}
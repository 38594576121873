exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-cartao-de-credito-classificacoes-jsx": () => import("./../../../src/pages/cartao-de-credito/classificacoes.jsx" /* webpackChunkName: "component---src-pages-cartao-de-credito-classificacoes-jsx" */),
  "component---src-pages-cartao-de-credito-credit-card-providers-uri-slug-jsx": () => import("./../../../src/pages/cartao-de-credito/{CreditCardProviders.uri__slug}.jsx" /* webpackChunkName: "component---src-pages-cartao-de-credito-credit-card-providers-uri-slug-jsx" */),
  "component---src-pages-cartao-de-credito-index-jsx": () => import("./../../../src/pages/cartao-de-credito/index.jsx" /* webpackChunkName: "component---src-pages-cartao-de-credito-index-jsx" */),
  "component---src-pages-credito-automovel-car-loans-uri-slug-jsx": () => import("./../../../src/pages/credito-automovel/{CarLoans.uri__slug}.jsx" /* webpackChunkName: "component---src-pages-credito-automovel-car-loans-uri-slug-jsx" */),
  "component---src-pages-credito-automovel-classificacoes-jsx": () => import("./../../../src/pages/credito-automovel/classificacoes.jsx" /* webpackChunkName: "component---src-pages-credito-automovel-classificacoes-jsx" */),
  "component---src-pages-credito-automovel-index-jsx": () => import("./../../../src/pages/credito-automovel/index.jsx" /* webpackChunkName: "component---src-pages-credito-automovel-index-jsx" */),
  "component---src-pages-credito-consolidado-classificacoes-jsx": () => import("./../../../src/pages/credito-consolidado/classificacoes.jsx" /* webpackChunkName: "component---src-pages-credito-consolidado-classificacoes-jsx" */),
  "component---src-pages-credito-consolidado-consolidated-loans-uri-slug-jsx": () => import("./../../../src/pages/credito-consolidado/{ConsolidatedLoans.uri__slug}.jsx" /* webpackChunkName: "component---src-pages-credito-consolidado-consolidated-loans-uri-slug-jsx" */),
  "component---src-pages-credito-consolidado-index-jsx": () => import("./../../../src/pages/credito-consolidado/index.jsx" /* webpackChunkName: "component---src-pages-credito-consolidado-index-jsx" */),
  "component---src-pages-credito-habitacao-classificacoes-jsx": () => import("./../../../src/pages/credito-habitacao/classificacoes.jsx" /* webpackChunkName: "component---src-pages-credito-habitacao-classificacoes-jsx" */),
  "component---src-pages-credito-habitacao-index-jsx": () => import("./../../../src/pages/credito-habitacao/index.jsx" /* webpackChunkName: "component---src-pages-credito-habitacao-index-jsx" */),
  "component---src-pages-credito-habitacao-mortgages-uri-slug-jsx": () => import("./../../../src/pages/credito-habitacao/{Mortgages.uri__slug}.jsx" /* webpackChunkName: "component---src-pages-credito-habitacao-mortgages-uri-slug-jsx" */),
  "component---src-pages-credito-pessoal-classificacoes-jsx": () => import("./../../../src/pages/credito-pessoal/classificacoes.jsx" /* webpackChunkName: "component---src-pages-credito-pessoal-classificacoes-jsx" */),
  "component---src-pages-credito-pessoal-index-jsx": () => import("./../../../src/pages/credito-pessoal/index.jsx" /* webpackChunkName: "component---src-pages-credito-pessoal-index-jsx" */),
  "component---src-pages-credito-pessoal-personal-loans-uri-slug-jsx": () => import("./../../../src/pages/credito-pessoal/{PersonalLoans.uri__slug}.jsx" /* webpackChunkName: "component---src-pages-credito-pessoal-personal-loans-uri-slug-jsx" */),
  "component---src-pages-formulario-categories-uri-slug-index-js": () => import("./../../../src/pages/formulario/{Categories.uri__slug}/index.js" /* webpackChunkName: "component---src-pages-formulario-categories-uri-slug-index-js" */),
  "component---src-pages-formulario-categories-uri-slug-jsx": () => import("./../../../src/pages/formulario/{Categories.uri__slug}/[...].jsx" /* webpackChunkName: "component---src-pages-formulario-categories-uri-slug-jsx" */),
  "component---src-pages-formulario-index-jsx": () => import("./../../../src/pages/formulario/index.jsx" /* webpackChunkName: "component---src-pages-formulario-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-pages-uri-url-jsx": () => import("./../../../src/pages/{LegalPages.uri__url}.jsx" /* webpackChunkName: "component---src-pages-legal-pages-uri-url-jsx" */),
  "component---src-pages-luz-e-gas-classificacoes-jsx": () => import("./../../../src/pages/luz-e-gas/classificacoes.jsx" /* webpackChunkName: "component---src-pages-luz-e-gas-classificacoes-jsx" */),
  "component---src-pages-luz-e-gas-energy-providers-uri-slug-jsx": () => import("./../../../src/pages/luz-e-gas/{EnergyProviders.uri__slug}.jsx" /* webpackChunkName: "component---src-pages-luz-e-gas-energy-providers-uri-slug-jsx" */),
  "component---src-pages-luz-e-gas-index-jsx": () => import("./../../../src/pages/luz-e-gas/index.jsx" /* webpackChunkName: "component---src-pages-luz-e-gas-index-jsx" */),
  "component---src-pages-sobre-nos-authors-uri-slug-jsx": () => import("./../../../src/pages/sobre-nos/{Authors.uri__slug}.jsx" /* webpackChunkName: "component---src-pages-sobre-nos-authors-uri-slug-jsx" */),
  "component---src-pages-sobre-nos-index-jsx": () => import("./../../../src/pages/sobre-nos/index.jsx" /* webpackChunkName: "component---src-pages-sobre-nos-index-jsx" */),
  "component---src-pages-telemovel-classificacoes-jsx": () => import("./../../../src/pages/telemovel/classificacoes.jsx" /* webpackChunkName: "component---src-pages-telemovel-classificacoes-jsx" */),
  "component---src-pages-telemovel-index-jsx": () => import("./../../../src/pages/telemovel/index.jsx" /* webpackChunkName: "component---src-pages-telemovel-index-jsx" */),
  "component---src-pages-telemovel-mobile-providers-uri-slug-jsx": () => import("./../../../src/pages/telemovel/{MobileProviders.uri__slug}.jsx" /* webpackChunkName: "component---src-pages-telemovel-mobile-providers-uri-slug-jsx" */),
  "component---src-pages-tv-net-voz-classificacoes-jsx": () => import("./../../../src/pages/tv-net-voz/classificacoes.jsx" /* webpackChunkName: "component---src-pages-tv-net-voz-classificacoes-jsx" */),
  "component---src-pages-tv-net-voz-index-jsx": () => import("./../../../src/pages/tv-net-voz/index.jsx" /* webpackChunkName: "component---src-pages-tv-net-voz-index-jsx" */),
  "component---src-pages-tv-net-voz-telecom-providers-uri-slug-jsx": () => import("./../../../src/pages/tv-net-voz/{TelecomProviders.uri__slug}.jsx" /* webpackChunkName: "component---src-pages-tv-net-voz-telecom-providers-uri-slug-jsx" */),
  "component---src-templates-info-page-jsx": () => import("./../../../src/templates/InfoPage.jsx" /* webpackChunkName: "component---src-templates-info-page-jsx" */)
}



import sprite from '../layout/sprite.svg'


export default function Icon({ i }) {

	return <svg xmlns="http://www.w3.org/2000/svg" 
		className={`Icon Icon-${i}`}
		aria-hidden='true'
		width='1em'
		height='1em'
	>
		<use href={`${sprite}#i-${i}`} />
	</svg>
}
